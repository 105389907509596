<template>
	<div class="Expander" :class="borderStyle ? '' : 'Expander-no-border-style'" v-if="!isDev">
		<div class="Expander__trigger dF jSB" @click="$emit('clicked')"
			:class="open ? 'active' : borderStyle ? 'beforeBorder' : ''">
			<!-- <svg

          class="Expander__trigger-Icon"
          :class="{open:open}"
          width="40" height="12"
          stroke="cornflowerblue">
            <polyline points="12,2 20,10 28,2" stroke-width="3" fill="none"></polyline>
        </svg> -->
			<span>
				{{ title }}
				<a-icon type="pushpin" v-if="pinned" />
			</span>
			<a-icon v-if="loading" type="loading" />
			<div v-else class="f1 dF jE Expander__icons-container">
				<a-icon v-if="showEdit" class="Edit-Icon-Expander Expander-edit-icon"
					:class="editAlone ? 'Expander__icon-raise' : ''" type="edit" @click="$emit('edit')" />
				<a-icon v-if="open && showSettings" class="Expander__trigger-Icon" type="setting" />
				<a-icon v-if="showArrow" class="Expander__trigger-Icon" type="arrow-right" :class="{ open: open }" />
			</div>


		</div>
		<transition :name="animation">
			<div class="Expander__body" v-if="open">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>
<script>
// padding 1rem 1.5rem

export default {
	props: {
		borderStyle: {
			type: Boolean,
			default: true
		},
		editAlone: {
			type: Boolean,
			default: false
		},
		arrow: {
			type: Boolean,
			default: true
		},
		showSettings: {
			type: Boolean
		},
		moreOptions: {
			type: Boolean
		},
		showEdit: {
			type: Boolean
		},
		loading: {
			type: Boolean,
			default: false
		},
		open: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'title'
		},
		animation: {
			type: String,
			default: 'rightToLeft'
			// validator: prop => ['leftToRight', 'bounceIn', 'bottomToTop'].includes(prop)
		},
		isDev: {
			type: Boolean,
			default: false
		},
		pinned: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		showArrow() {
			if (this.showSettings) return false
			return this.arrow
		}
	},
	watch: {
		open(val) {
			if (val) this.$emit('selected')
		}
	},
	data() {
		return {
		}
	},
	methods: {

	}
}
</script>

<style lang="scss" scoped>
.Expander::selection {
	background: none;
}

.beforeBorder {
	position: relative;
}

.beforeBorder:before {
	transition: opacity 0.1s linear, transform 0.5s ease-in-out;
	position: absolute;
	border-bottom: 1px solid currentColor;
	content: '';
	width: 100%;
	left: 0;
	bottom: -1px;
}

.beforeBorder:not(:hover)::before {
	transform: scaleX(0);
	opacity: 0;
}

.Accordion {
	background: #fff;
	box-shadow: 0 1px 12px 1PX rgba(0, 0, 0, 0.25);
	overflow: hidden;
}

.Expander__body {
	background: #f8f8f8;
}

.Expander__trigger {
	cursor: pointer;
	padding: 0.7rem 0.5rem;
	border-bottom: 1px solid #efefef;
}

.Expander__trigger:hover {
	color: var(--purple);
}

.Expander__trigger.active {
	border-bottom-color: var(--orange);
}

.Expander__trigger-Icon {
	transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}

.Expander__trigger-Icon.open {
	stroke: var(--primary);
	transform: rotate(90deg);
}

.Expander__icons-container {
	i+i {
		margin-left: 1rem;
	}
}

.Expander__icon-raise {
	position: relative;
	z-index: 100;
}

.Expander-edit-icon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid var(--med-gray);
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		color: var(--orange);
		border-color: var(--orange);
	}
}
</style>
