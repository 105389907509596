<template>
	<div>
		<a-tooltip title="Switch project" overlayClassName="change-tooltip-color" placement="bottom">
			<a-avatar shape="circle" @click="visible = true;" size="large" class="top-bar-icon">
				<a-icon type="swap" />
			</a-avatar>
		</a-tooltip>
		<a-modal
			centered
			title="Switch project"
			:visible="visible"
			@cancel="visible = false;"
			:footer="null"
			:afterClose="resetOnClose"
			width="800px"
			>
			<div class="mb-3">
				<a-spin :spinning="loading" class="">
					<template>
						<a-input ref="search" v-model="search" size="large" placeholder="Search for a project" class="mb-3" style="position: sticky;" allowClear>
							<a-icon slot="suffix" type="search" />
						</a-input>
						<a-list bordered :data-source="instancesWithAccess" style="max-height: calc(70vh); overflow: auto;" >
							<a-list-item slot="renderItem" slot-scope="item, index" class="project-item">
								<a-list-item-meta>
									<div slot="avatar">
										<div class="dF jC aC">
											<div class="hide-hexagon hexagon hexagon2">
												<div class="hexagon-in1">
													<div
														class="hexagon-in2"
														:style="{
															'background-color':
																getBackground(item),
														}"
													></div>
												</div>
											</div>
										</div>
									</div>
									<span slot="title">
										{{ item.readableId }} - {{ item.name }}
										<i v-if="item.isPinned" style="color: #fed762" class="fa fa-star ml-2" />
									</span>
								</a-list-item-meta>
								<a slot="actions">
									<div class="dF jC aC">
										<a-tooltip overlayClassName="change-tooltip-color" class="mr-2">
											<span slot="title" v-if="!item.canAccessApp">
												This app is not accessible for this project, you can switch to the workplace.
											</span>
											<a-button :disabled="!item.canAccessApp" @click="switchProject(item)" size="small">
												<a-icon type="swap" />
												<span>Switch</span>
											</a-button>
										</a-tooltip>
										
										<a-button v-if="$bhapp !== 'admin'" @click="switchProject(item, true)" size="small">
											<a-icon type="home" />
											
											<span>Workplace</span>
										</a-button>
									</div>
								</a>
							</a-list-item>
						</a-list>
					</template>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { setCookie } from 'bh-mod';

export default {
	name: 'ProjectSwitcher',

	data() {
		return {
			visible: false,
			loading: false,
			loaded: false,
			search: '',
			projects: [],
		}
	},

	watch: {
		async visible(visible) {
			if (visible && !this.loaded) {
				await this.loadProjects();
			}
			
			if (visible) {
				this.$nextTick(() => {
					this.$refs.search.$refs.input.focus();
				});
			}
		}
	},

	mounted() {
		document.addEventListener('keydown', this.keyboardShortcut);
	},

	beforeDestroy() {
		document.removeEventListener('keydown', this.keyboardShortcut);
	},

	computed: {
		currentInstance() {
			return this.$store.state.instance;
		},

		userSettings() {
			return this.$store.state.user.user.settings;
		},

		pin() {
			const pinOptions = this.$store.state.user.user?.settings?.options?.pin;
			
			return pinOptions && pinOptions.length ? pinOptions : [];
        },

		instancesWithAccess() {
			const instances = this.search ? this.projects.filter(project => (
				project.instance.name.toLowerCase().includes(this.search.toLowerCase()) ||
				project.instance.readableId.toLowerCase().includes(this.search.toLowerCase())
			)) : this.projects;

			return instances.map(project => ({
					...project.instance,
					canAccessApp: this.$bhapp === 'admin' || 
						(
							(project.role.permissions.admin || project.role.permissions[this.$bhapp]) &&
							project.instance.apps.includes(this.$bhapp)
						),
					isPinned: this.pin.includes(project.instance.id),
				}))
				.filter(project => project.id !== this.currentInstance.id)
				.sort((a, b) => a.name.localeCompare(b.name))
				.sort((a, b) => b.isPinned - a.isPinned);
		}
	},

	methods: {
		keyboardShortcut(e) {
			if (e.ctrlKey && e.key === 'k') {
				this.visible = true;
				e.preventDefault();
			}
		},

		async loadProjects() {
			this.loading = true;

			const response = await this.$api.get('/instances');
			this.projects = response.data;

			this.loading = false;
			this.loaded = true;
		},

		switchProject(project, workplace = false) {
			const location = workplace ? `https://admin.bildhive.${this.$tld}` : window.location.origin;
			
			this.changeProject(project, location);
		},

		changeProject(project, location) {
			this.loading = true;
			setCookie('instance', project.id);
			window.location.href = location;
		},

		resetOnClose() {
			this.search = '';
		},

		getBackground(instance) {
            const avatar = instance.avatar;
            const id = instance.id;
            let found = this.instancesWithAccess.find((x) => x.id === id);
            if (found) {
                if (found.color) {
                    if (this.userSettings.options?.colour?.[id]) {
                        return this.userSettings.options.colour[id];
                    } else if (this.userSettings.options?.colour?.[id] !== "") {
                        return found.color;
                    }
                } else {
					if (this.userSettings.options?.colour?.[id]) {
						return this.userSettings.options.colour[id];
                    } else if (avatar && typeof avatar === "string") {
                        return avatar;
                    } else return "#FEC267";
                }
            } else return "";
        },
	}
}
</script>

<style lang="scss" scoped>
.project-item {
	transition: ease-in-out 0.2s background-color;

	&:hover {
		background-color: var(--primary-vl);
	}
}

.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #d0c9d6;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon2 {
    width: 25px;
    height: 20px;
    margin-top: 2px;
}

.ant-list-item-meta {
	align-items: center;
}

.top-bar-icon {
	color: var(--dark-gray);
	position: relative;
	background: none;
	border: 1px solid transparent;
	cursor: pointer;

	.ant-avatar-string {
		display: block;
	}

	.ant-avatar-string i {
		color: inherit;
	}

	&:hover {
		color: var(--dark-purple);
		// background:var(--off-white-dark);
		border-color: var(--light-purple);
	}
}
</style>